var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vl-layout" },
    [
      _c(
        "vl-region",
        [
          _c(
            "vl-layout",
            [
              _c(
                "vl-form-grid",
                {
                  staticClass: "vl-grid--align-center vl-grid--v-center",
                  attrs: { "mod-stacked": "" },
                },
                [
                  _c(
                    "vl-column",
                    {
                      staticClass: "vl-u-hidden vl-u-visible--s",
                      attrs: { width: "6" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center",
                        },
                        [
                          _c("img", {
                            attrs: { src: "/images/page-not-found.svg" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "vl-column",
                    { attrs: { width: "6", "width-s": "8", "width-xs": "12" } },
                    [
                      _c("vl-title", { attrs: { "tag-name": "h2" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("general.error.notfound.title")) +
                            " "
                        ),
                      ]),
                      _c("vl-typography", [
                        _vm._v(
                          _vm._s(_vm.$t("general.error.notfound.subtitle"))
                        ),
                      ]),
                      _c("vl-u-spacer", { attrs: { "mod-medium": "" } }),
                      _c(
                        "vl-action-group",
                        [
                          _c(
                            "vl-button",
                            {
                              staticClass: "vl-button vl-button--large",
                              attrs: {
                                "tag-name": "a",
                                icon: "text-redo",
                                "mod-icon-before": "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.returnToStart.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("general.error.notfound.backToStart")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vl-column",
                    { staticClass: "vl-u-hidden--s", attrs: { width: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center",
                        },
                        [
                          _c("img", {
                            attrs: { src: "/images/page-not-found.svg" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }