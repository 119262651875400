import { render, staticRenderFns } from "./owner.html?vue&type=template&id=5aca2474&scoped=true&external"
import script from "./owner.ts?vue&type=script&lang=ts&external"
export * from "./owner.ts?vue&type=script&lang=ts&external"
import style0 from "./owner.vue?vue&type=style&index=0&id=5aca2474&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aca2474",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/20/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5aca2474')) {
      api.createRecord('5aca2474', component.options)
    } else {
      api.reload('5aca2474', component.options)
    }
    module.hot.accept("./owner.html?vue&type=template&id=5aca2474&scoped=true&external", function () {
      api.rerender('5aca2474', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/kadaster/sidebar/owner/owner.vue"
export default component.exports