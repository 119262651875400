import { render, staticRenderFns } from "./TabBuitenlandsVoertuig.html?vue&type=template&id=3bc7c9ce&scoped=true&external"
import script from "./TabBuitenlandsVoertuig.ts?vue&type=script&lang=ts&external"
export * from "./TabBuitenlandsVoertuig.ts?vue&type=script&lang=ts&external"
import style0 from "./TabBuitenlandsVoertuig.vue?vue&type=style&index=0&id=3bc7c9ce&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc7c9ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/20/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3bc7c9ce')) {
      api.createRecord('3bc7c9ce', component.options)
    } else {
      api.reload('3bc7c9ce', component.options)
    }
    module.hot.accept("./TabBuitenlandsVoertuig.html?vue&type=template&id=3bc7c9ce&scoped=true&external", function () {
      api.rerender('3bc7c9ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/mobiliteit/tabs/buitenlandsvoertuig/TabBuitenlandsVoertuig.vue"
export default component.exports