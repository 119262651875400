
import { Component, Watch } from 'vue-property-decorator'
import DynamicBreadcrumb from '@/infrastructure/mixins/dynamicBreadcrumb'
import { updatePageTitle } from '@/infrastructure/mixins/dynamicPageTitle'
import { IEmbedConfiguration, IEmbedSettings, service } from 'powerbi-client'
import moment from 'moment'
import { GACategory } from '@/libs/constants'
import { deepCopy } from '@/libs/JsonTransformation'
import { reportingStore } from '@/store/reporting'

interface IReport {
  reportId: string
  reportName: string
  reportUrl: string
}

interface IToken {
  token: string
  tokenId: string
  expiration: Date
}

export interface IReportObject {
  type: string
  reports: IReport[]
  token: IToken
}

@Component
export default class Rapport extends DynamicBreadcrumb {
  report
  reportMobile
  powerbi: {
    default: any
    service: any
    models: any
    factories: any
  }
  powerBiService: service.Service
  powerBiServiceMobile: service.Service
  settings: IEmbedSettings = {
    localeSettings: {
      language: 'nl',
      formatLocale: 'nl',
    },
    panes: {
      bookmarks: {
        visible: false,
      },
      fields: {
        expanded: true,
      },
      filters: {
        expanded: true,
        visible: true,
      },
      pageNavigation: {
        visible: true,
      },
      selection: {
        visible: true,
      },
      syncSlicers: {
        visible: true,
      },
      visualizations: {
        expanded: true,
      },
    },
    bars: {
      actionBar: {
        visible: false,
      },
    },
    layoutType: 0,
  }
  showMobile = false
  reportObject: IReportObject = null
  reportStore = reportingStore()

  get reportId(): string {
    return this.reportStore.id
  }

  get reportWorkspaceId(): string {
    return this.reportStore.workspaceId
  }

  get reportName(): string {
    return this.reportStore.name
  }

  get reportTitle(): string {
    if (this.$route.params && this.$route.params.titel) {
      return this.$route.params.titel
    }
    return ''
  }

  beforeMount() {
    this.onWindowResize()
  }

  async mounted() {
    this.powerbi = await this.loadClient()

    this.reportStore.getReport().then((report) => {
      this.reportObject = this.mapToReport(report.embedParams)
      if (this.reportObject) {
        this.showReport(this.reportObject)
      }
    })

    if (this.reportStore.fromReportSelection) {
      this.setBreadCrumbAndPageTitle(this.reportTitle)
    }

    window.addEventListener('resize', this.onWindowResize)
    this.sendGoogleAnalyticsEvent(this.reportName)
  }

  mapToReport = (response: any): IReportObject => {
    if (!response) return null
    return {
      type: response.type,
      reports: response.embedReport.map((report: any): IReport => {
        return {
          reportId: report.reportId,
          reportName: report.reportName,
          reportUrl: report.embedUrl,
        }
      }),
      token: {
        token: response.embedToken.token,
        tokenId: response.embedToken.tokenId,
        expiration: response.embedToken.expiration,
      },
    }
  }

  setBreadCrumbAndPageTitle(pageTitle: string) {
    this.updateBreadcrumb(pageTitle)
    updatePageTitle(this.$route.name, pageTitle)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  onWindowResize() {
    if (window.innerWidth <= 768) {
      this.showMobile = true
    } else {
      this.showMobile = false
    }
  }

  @Watch('showMobile')
  onMobileChanged(old) {
    if (this.reportObject) this.showReport(this.reportObject)
  }

  async loadClient() {
    const powerbi = await import('powerbi-client')
    return powerbi
  }

  showReport(reportObject: IReportObject) {
    // Embed URL
    const embedUrl = reportObject.reports[0].reportUrl
    const embedReportId = reportObject.reports[0].reportId
    const token = reportObject.token

    const settingsMobile = deepCopy(this.settings)

    settingsMobile.layoutType = this.powerbi.models.LayoutType.MobilePortrait

    if (this.showMobile) {
      const configMobile: IEmbedConfiguration = {
        type: reportObject.type,
        tokenType: this.powerbi.models.TokenType.Embed,
        accessToken: token.token,
        embedUrl: embedUrl,
        id: embedReportId,
        filters: [],
        settings: settingsMobile,
        pageView: this.powerbi.models.DisplayOption.fitToWidth,
      }
      const reportContainerMobile = document.getElementById('reportContainerMobile')

      this.powerBiServiceMobile = new this.powerbi.service.Service(
        this.powerbi.factories.hpmFactory,
        this.powerbi.factories.wpmpFactory,
        this.powerbi.factories.routerFactory,
        configMobile,
      )

      this.reportMobile = this.powerBiServiceMobile.embed(reportContainerMobile, configMobile)

      this.reportMobile.off('loaded')
      this.reportMobile.on('loaded', () => {
        this.setTokenExpirationListener(token.expiration, 2)
      })
      this.reportMobile.on('error', () => {})
    } else {
      const config: IEmbedConfiguration = {
        type: reportObject.type,
        tokenType: this.powerbi.models.TokenType.Embed,
        accessToken: token.token,
        embedUrl: embedUrl,
        id: embedReportId,
        filters: [],
        settings: this.settings,
        pageView: this.powerbi.models.DisplayOption.fitToWidth,
      }
      const reportContainer = document.getElementById('reportContainer')

      this.powerBiService = new this.powerbi.service.Service(
        this.powerbi.factories.hpmFactory,
        this.powerbi.factories.wpmpFactory,
        this.powerbi.factories.routerFactory,
        config,
      )
      this.report = this.powerBiService.embed(reportContainer, config)

      this.report.off('loaded')
      this.report.on('loaded', () => {
        this.setTokenExpirationListener(token.expiration, 2)
      })
      this.report.on('error', () => {})
    }
  }

  setTokenExpirationListener(tokenExpiration, minutesToRefresh = 2) {
    // get current time
    const currentTime = new Date(Date.now()).getTime()

    const expiration = moment(tokenExpiration).valueOf()
    const safetyInterval = minutesToRefresh * 60 * 1000

    // time until token refresh in milliseconds
    const timeout = expiration - currentTime - safetyInterval

    // if token already expired, generate new token and set the access token
    if (timeout <= 0) {
      this.updateToken()
    }
    // set timeout so minutesToRefresh minutes before token expires, token will be updated
    else {
      setTimeout(function () {
        this.updateToken()
      }, timeout)
    }
  }

  updateToken() {
    // Generate new EmbedToken
    this.$api.getRapport(this.reportWorkspaceId, this.reportId).then((response) => {
      const token = response.embedParams.embedToken
      // Get a reference to the embedded report HTML element
      const embedContainer = document.getElementById('reportContainer')

      // Get a reference to the embedded report.
      const report = this.powerBiService.get(embedContainer)

      // Set AccessToken
      report.setAccessToken(token.token).then(() => {
        this.setTokenExpirationListener(token.expiration, 5)
      })
    })
  }

  private sendGoogleAnalyticsEvent(reportName: string) {
    this.$ga.sendEvent(GACategory.rapportering.category, `${GACategory.rapportering.bekijkRapport}${reportName}`)
  }
}
