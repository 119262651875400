var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { attrs: { id: "report" } }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.showMobile,
          expression: "!showMobile",
        },
      ],
      staticStyle: { height: "85vh" },
      attrs: { id: "reportContainer" },
    }),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showMobile,
          expression: "showMobile",
        },
      ],
      staticStyle: { height: "85vh" },
      attrs: { id: "reportContainerMobile" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }