import { render, staticRenderFns } from "./mo-min-max.html?vue&type=template&id=07811a15&external"
import script from "./mo-min-max.ts?vue&type=script&lang=ts&external"
export * from "./mo-min-max.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/20/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07811a15')) {
      api.createRecord('07811a15', component.options)
    } else {
      api.reload('07811a15', component.options)
    }
    module.hot.accept("./mo-min-max.html?vue&type=template&id=07811a15&external", function () {
      api.rerender('07811a15', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/mo-min-max/mo-min-max.vue"
export default component.exports