import { render, staticRenderFns } from "./mo-multiselect-wrapper.html?vue&type=template&id=004f9b56&external"
import script from "./mo-multiselect-wrapper.ts?vue&type=script&lang=ts&external"
export * from "./mo-multiselect-wrapper.ts?vue&type=script&lang=ts&external"
import style0 from "./mo-multiselect-wrapper.vue?vue&type=style&index=0&id=004f9b56&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/20/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('004f9b56')) {
      api.createRecord('004f9b56', component.options)
    } else {
      api.reload('004f9b56', component.options)
    }
    module.hot.accept("./mo-multiselect-wrapper.html?vue&type=template&id=004f9b56&external", function () {
      api.rerender('004f9b56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/mo-multiselect-wrapper/mo-multiselect-wrapper.vue"
export default component.exports