var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vl-layout" },
    [
      _c("vl-title", { attrs: { "tag-name": "h1" } }, [
        _vm._v(" " + _vm._s(_vm.$t("index.label.title")) + " "),
      ]),
      _c(
        "vl-grid",
        { attrs: { "mod-stacked": "" } },
        [
          _vm.hasMounted && _vm.domains.length === 0
            ? _c(
                "vl-column",
                [
                  _c("mo-alert", {
                    attrs: {
                      "mod-warning": "",
                      title: _vm.$t("index.modal.title"),
                      content: _vm.$t("index.modal.content"),
                      decorator: "vl-u-spacer--large",
                      icon: "alert-triangle",
                    },
                  }),
                ],
                1
              )
            : _vm._l(_vm.domains, function (domain) {
                return _c(
                  "vl-column",
                  {
                    key: domain.title,
                    staticStyle: { display: "flex" },
                    attrs: { width: "4", "width-m": "6", "width-s": "12" },
                  },
                  [
                    _c(
                      "vl-doormat",
                      {
                        staticClass: "js-vl-equal-height",
                        staticStyle: { flex: "1" },
                        attrs: {
                          title: domain.title,
                          href: "javascript:void(0)",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.navigateToDomain(domain)
                          },
                        },
                      },
                      [
                        _c(
                          "vl-typography",
                          [
                            _c("vl-column", [
                              _c(
                                "ul",
                                _vm._l(domain.features, function (feature) {
                                  return _c("li", { key: feature }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(`permissions.${feature}`)
                                        ) +
                                        " "
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]),
                            _c("mo-icon", { attrs: { icon: domain.icon } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }