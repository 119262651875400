var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "vl-grid",
    [
      !_vm.processing && !_vm.jobFound && !_vm.validationErrors
        ? [
            _c(
              "vl-column",
              { attrs: { width: "6", "width-m": "12", "width-s": "12" } },
              [
                _c(
                  "mo-formfield-decorator",
                  [
                    _c("mo-upload-wrapper", {
                      key: _vm.uploadInstance,
                      ref: "files",
                      attrs: {
                        value: "recipientFiles",
                        options: _vm.options,
                        id: "files",
                        url: "/sss/api/backend/kadaster/zoekperceel/bulk",
                        name: "files",
                        "upload-label": "Bestand opladen",
                        "upload-drag-text":
                          "Sleep het bestand naar hier om toe te voegen",
                        "max-files": "1",
                        "max-files-msg":
                          "Gelieve maximum 1 bestand toe te voegen",
                        "max-filesize": "10",
                        "max-filesize-msg":
                          "Het bestand mag maximaal 10MB zijn",
                        "allowed-file-types":
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      },
                      model: {
                        value: _vm.files,
                        callback: function ($$v) {
                          _vm.files = $$v
                        },
                        expression: "files",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "vl-column",
              { attrs: { width: "5", "width-m": "12", "width-s": "12" } },
              [
                _c("vl-typography", [
                  _c("ul", { staticClass: "upload-guidelines" }, [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.vlaanderen.be/magda/het-aanbod-van-magda/aansluiten-op-magda/magda-online/domein-kadaster/zoeken-via-perceel",
                            target: "_blank",
                          },
                        },
                        [_vm._v("Lees eerst de handleiding")]
                      ),
                    ]),
                    _c("li", [
                      _vm._v("Enkel een .xlsx-bestand met vast patroon"),
                    ]),
                    _c("li", [_vm._v("Maximale bestandsgrootte: 10 MB")]),
                    _c("li", [_vm._v("Maximum 100 percelen")]),
                  ]),
                ]),
              ],
              1
            ),
            _c("vl-column"),
          ]
        : _vm._e(),
      _vm.processing && !_vm.jobFound && !_vm.validationErrors
        ? [
            _c("vl-column", [
              _c(
                "div",
                { staticClass: "vl-u-align-center" },
                [
                  _c("br"),
                  _c("br"),
                  _c("vl-loader", {
                    attrs: { message: "De gegevens worden verwerkt." },
                  }),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm.jobFound && !_vm.validationErrors
        ? [
            _c(
              "vl-column",
              [
                _vm.notFoundWarning > 0
                  ? _c("vl-alert", {
                      directives: [
                        {
                          name: "vl-spacer",
                          rawName: "v-vl-spacer:bottom.small",
                          arg: "bottom",
                          modifiers: { small: true },
                        },
                      ],
                      attrs: {
                        "mod-small": "",
                        "mod-warning": "",
                        title: _vm.$tc(
                          "kadaster.bulk.parcelNotFoundAlert",
                          _vm.notFoundWarning.value
                        ),
                      },
                    })
                  : _vm._e(),
                _vm.jobId
                  ? _c("mo-bulk-processing", {
                      ref: "bulkJobProcessing",
                      attrs: {
                        jobId: _vm.jobId,
                        jobType: "Kadaster.zoekPerceel",
                        "can-download": false,
                        "replace-upload": true,
                      },
                      on: {
                        finished: _vm.onJobFinished,
                        clear: _vm.clearFields,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.validationErrors
        ? [
            _c(
              "vl-column",
              {
                directives: [
                  {
                    name: "vl-spacer",
                    rawName: "v-vl-spacer:bottom.small",
                    arg: "bottom",
                    modifiers: { small: true },
                  },
                ],
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vl-alert vl-alert--error",
                    attrs: { role: "alert" },
                  },
                  [
                    _c("div", { staticClass: "vl-alert__icon" }, [
                      _c("i", {
                        staticClass: "vl-vi vl-vi-alert-triangle",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]),
                    _c(
                      "vl-grid",
                      [
                        _c("vl-column", { attrs: { width: "9" } }, [
                          _c("p", { staticClass: "vl-alert__title" }, [
                            _vm._v("Uw bestand is niet gevalideerd."),
                          ]),
                        ]),
                        _c("vl-column", { staticClass: "vl-alert__message" }, [
                          _c("p", [
                            _vm._v(" Meer uitleg vindt u op de "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: "https://www.vlaanderen.be/magda/het-aanbod-van-magda/aansluiten-op-magda/magda-online/domein-kadaster/zoeken-via-perceel",
                                },
                              },
                              [_vm._v("helppagina")]
                            ),
                            _vm._v(". "),
                          ]),
                        ]),
                        _c(
                          "vl-column",
                          { staticClass: "vl-alert__actions" },
                          [
                            _c(
                              "vl-button",
                              {
                                attrs: { "mod-secondary": "" },
                                on: { click: _vm.clearFields },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "vl-button__icon vl-button__icon--before vl-vi vl-vi-text-undo",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(" Bestand Vervangen "),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      _c(
        "vl-column",
        [
          _c(
            "vl-action-group",
            [
              _c(
                "vl-button",
                {
                  attrs: {
                    modLoading: _vm.processing,
                    "mod-disabled": _vm.processing || _vm.jobFound,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.upload.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("general.label.search", "nl")))]
              ),
              _c(
                "vl-button",
                {
                  attrs: {
                    "mod-disabled": _vm.processing,
                    "mod-tertiary": "",
                    "mod-narrow": "",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clearFields.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("general.label.clearFields", "nl")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }