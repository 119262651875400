import { render, staticRenderFns } from "./index.html?vue&type=template&id=5af70389&external"
import script from "./index.ts?vue&type=script&lang=ts&external"
export * from "./index.ts?vue&type=script&lang=ts&external"
import style0 from "./index.vue?vue&type=style&index=0&id=5af70389&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/20/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5af70389')) {
      api.createRecord('5af70389', component.options)
    } else {
      api.reload('5af70389', component.options)
    }
    module.hot.accept("./index.html?vue&type=template&id=5af70389&external", function () {
      api.rerender('5af70389', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/mobiliteit/index.vue"
export default component.exports