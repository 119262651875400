<template>
  <default-layout />
</template>

<script>
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import defaultLayout from '@/layouts/default.vue'
@Component({
  components: {
    defaultLayout,
  },
})
export default class App extends Vue {
  async created() {
  }
}
</script>

<style lang="scss">
.content {
  min-height: calc(100vh - 160px);
}
.content--padded:first-child {
  padding-top: 12rem !important;
}
</style>
