import { render, staticRenderFns } from "./partition-sidebar.html?vue&type=template&id=191380d2&scoped=true&external"
import script from "./partition-sidebar.ts?vue&type=script&lang=ts&external"
export * from "./partition-sidebar.ts?vue&type=script&lang=ts&external"
import style0 from "./partition-sidebar.vue?vue&type=style&index=0&id=191380d2&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "191380d2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/20/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('191380d2')) {
      api.createRecord('191380d2', component.options)
    } else {
      api.reload('191380d2', component.options)
    }
    module.hot.accept("./partition-sidebar.html?vue&type=template&id=191380d2&scoped=true&external", function () {
      api.rerender('191380d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/kadaster/sidebar/partition-sidebar/partition-sidebar.vue"
export default component.exports