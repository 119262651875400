import { render, staticRenderFns } from "./history-status.html?vue&type=template&id=b7d8dbd0&scoped=true&external"
import script from "./history-status.ts?vue&type=script&lang=ts&external"
export * from "./history-status.ts?vue&type=script&lang=ts&external"
import style0 from "./history-status.vue?vue&type=style&index=0&id=b7d8dbd0&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7d8dbd0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/20/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b7d8dbd0')) {
      api.createRecord('b7d8dbd0', component.options)
    } else {
      api.reload('b7d8dbd0', component.options)
    }
    module.hot.accept("./history-status.html?vue&type=template&id=b7d8dbd0&scoped=true&external", function () {
      api.rerender('b7d8dbd0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/kadaster/ownership-history/history-status/history-status.vue"
export default component.exports