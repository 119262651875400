var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("vl-multiselect", {
    ref: "componentRef",
    attrs: {
      "custom-label": _setup.labelResolver,
      loading: _setup.loading,
      options: _setup.selectOptions,
      "mod-multiple": false,
      "mod-error": _setup.validation.showError.value,
      placeholder: "Typ om een gemeente te zoeken",
      "group-values": !_setup.searchValue ? "cities" : null,
    },
    on: { "search-change": _setup.searchChanged, input: _setup.select },
    model: {
      value: _setup.localValue,
      callback: function ($$v) {
        _setup.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }