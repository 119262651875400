
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { PageNames, Pages } from '@/assets/constants'
import misconfiguredRolesMixin from '@/mixins/misconfiguredRoles'
import privacyMixin from '@/mixins/privacy'
import svg4everybody from 'svg4everybody'
import { routeMap } from '@/assets/routeMap'
import { DomainType } from '@/declarations/references.models'
import { Permission } from '@/libs/constants'
import { IsLocalizedDomain } from '@/infrastructure/router/router.utils'
import { featureToggleStore } from '@/store/feature-toggle'
import { userStore } from '@/store/user'

interface IDomainObject {
  type: DomainType
  title: string
  routeName: string
  features: string[]
  icon: string
}

@Component
export default class Index extends Mixins(privacyMixin, misconfiguredRolesMixin) {
  title: string = Pages[PageNames.Home].title
  description: string = Pages[PageNames.Home].description
  hasMounted = false

  toggleStore = featureToggleStore()
  userStore = userStore()

  get domains(): IDomainObject[] {
    const activeRoleMap = this.userStore.activeRoleMap

    const domains: IDomainObject[] = []

    const domainIconMap = {
      logging: 'icon-eye',
      person: 'icon-people',
      messages: 'icon-mail',
      led: 'icon-hat',
      mobility: 'icon-car',
      healthattestations: 'icon-health',
      reporting: '',
      docprovider: 'icon-cog',
      socialsecurity: 'icon-socialsecurity',
      landregistry: 'icon-landregistry',
      pushnotifications: 'icon-notifications',
      family: 'icon-people',
      company: 'icon-suitcase',
    }

    activeRoleMap.forEach((map) => {
      const domainObject: IDomainObject = {
        type: map.domain,
        title: this.$t(`domains.${map.domain?.toLowerCase()}`),
        routeName: routeMap[map.domain?.toLowerCase()],
        features: this.filterFeatures(map)
          .map((p) => p.split('.')[1])
          .sort(
            (a, b) =>
              this.featureSort({ domain: map.domain?.toLowerCase(), permissions: a }) - this.featureSort({ domain: map.domain.toLowerCase(), permissions: b }),
          ),
        icon: domainIconMap[map.domain?.toLowerCase()],
      }
      if (domainObject.features.length > 0) {
        domains.push(domainObject)
      }
    })
    return domains.sort((a, b) => (a.title < b.title ? -1 : 1))
  }

  @Watch('domains', { deep: true })
  onDomainsChanged() {
    if (this.domains.length === 1) {
      this.navigateToDomain(this.domains[0])
    }
  }

  filterFeatures(map) {
    return map.permissions.filter((permission) => {
      if (!this.filterLandRegistryPermissions(map, permission)) return false
      if (!this.filterSocialSecurityPermissions(map, permission)) return false
      if (!this.filterMessagesPermissions(map, permission)) return false
      if (!this.filterCompanyPermissions(map, permission)) return false

      //check if translation exists
      if (!this.$te(`permissions.${permission.split('.')[1]}`)) {
        return false
      }
      return true
    })
  }

  filterMessagesPermissions(map, permission) {
    if ((DomainType[map.domain] as unknown as DomainType) === DomainType.Messages && !this.toggleStore.isEmailActive) {
      if (permission === Permission.document.verstuurEmail) {
        return false
      }
    }

    return true
  }


  filterLandRegistryPermissions(map, permission) {
    if (permission === Permission.kadaster.zoekVerkoopprijzen && !this.toggleStore.isKadasterZoekVerkoopPrijzenActive) {
      return false
    }

    if (permission === Permission.kadaster.geefHistoriekEigendomstoestand && !this.toggleStore.isKadasterGeefHistoriekEigendomstoestandActive) {
      return false
    }

    return true
  }

  filterSocialSecurityPermissions(map, permission) {
    if (permission === Permission.socialSecurity.geefLeefloon && !this.toggleStore.isLeefloonActive) {
      return false
    }

    return true
  }

  filterCompanyPermissions(map, permission) {
    if (permission === Permission.onderneming.geefPCenTW && !this.toggleStore.isOndernemingGeefPCenTWActive) {
      return false
    }
    if (permission === Permission.onderneming.geefDeelnemingen && !this.toggleStore.isOndernemingGeefDeelnemingenActive) {
      return false
    }
    return true
  }

  featureSort = ({ domain, permissions }: { domain: string; permissions: string }): number => {
    const sortWeight = {
      person: ['geefpersoon', 'geefhistoriekpersoon', 'zoekpersoonopnaam', 'zoekpersoonopadres', 'creeerbis', 'geefgezinssamenstelling'],
      messages: ['files/upload/EMAIL', 'files/upload/PAPER', 'publishMessageCitizen', 'publishMessageEnterprise'],
      led: ['geefbewijs'],
      mobility: ['registrations', 'crossborderTitulars'],
      socialsecurity: ['geefsociaalstatuut', 'geefleefloon'],
      pushnotifications: ['notifications', 'management/receivers'],
      landregistry: ['zoekPerceel', 'geefeigendomstoestanden', 'zoekeigendomstoestanden', 'zoekverkoopprijzen', 'geefhistoriekeigendomstoestand'],
      company: ['geefonderneming', 'geefpcentw', 'geefdeelnemingen'],
    }
    const domainWeight: string[] = sortWeight[domain]
    if (domainWeight) {
      return domainWeight.indexOf(permissions.toLowerCase())
    }
    return -1
  }

  mounted() {
    if (this.domains.length === 1) {
      this.navigateToDomain(this.domains[0])
      return
    }

    this.hasMounted = true
    svg4everybody()
  }

  navigateToDomain(domain: IDomainObject) {
    if (IsLocalizedDomain(domain.type)) {
      const path = this.localePath({ name: domain.routeName })
      this.$router.push({ path })
    } else {
      const name = domain.routeName
      this.$router.push({ name })
    }
  }
}
